import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';

export interface SmsField {
    customer_id: number | null,
    message: string
}

export const iniSmsField = (): SmsField => {
    return {
        customer_id: null,
        message: ""
    }
}

interface UserLayoutPageProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    sendSms: (message: string) => void
}

const SendSms: React.FC<UserLayoutPageProps> = ({
    open,
    setOpen,
    sendSms
}) => {
    const [inputField, setInputField] = React.useState(iniSmsField());


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputField((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const _confirmUpdate = () => {
        sendSms(inputField.message)
        setInputField(iniSmsField())
        setOpen(false)

    }

    const _cancelUpdate = () => {
        setInputField(iniSmsField())
        setOpen(false)
    }


    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle>{"SEND SMS"}</DialogTitle>
            <DialogContent>

                <TextField
                    name="message"
                    label="Message"
                    value={inputField.message}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    margin="normal"
                    type="string"
                    style={{ width: '100%' }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => _cancelUpdate()} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => _confirmUpdate()} variant="contained" color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog >
    );
};

export default SendSms;


